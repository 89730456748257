/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extend_3f914814 from '/home/ubuntu/frontend/modules/@ergonode/attributes/src/config/extends.js';
import extend_cf4eed3c from '/home/ubuntu/frontend/modules/@ergonode/attribute-groups/src/config/extends.js';
import extend_050e59ce from '/home/ubuntu/frontend/modules/@ergonode/categories/src/config/extends.js';
import extend_18a9d2fa from '/home/ubuntu/frontend/modules/@ergonode/category-trees/src/config/extends.js';
import extend_27167d48 from '/home/ubuntu/frontend/modules/@ergonode/core/src/config/extends.js';
import extend_6b0b720e from '/home/ubuntu/frontend/modules/@ergonode/dashboard/src/config/extends.js';
import extend_a8ee3666 from '/home/ubuntu/frontend/modules/@ergonode/channels/src/config/extends.js';
import extend_c5fdeb6e from '/home/ubuntu/frontend/modules/@ergonode/media/src/config/extends.js';
import extend_2969a04d from '/home/ubuntu/frontend/modules/@ergonode/notifications/src/config/extends.js';
import extend_2fd8a38c from '/home/ubuntu/frontend/modules/@ergonode/collections/src/config/extends.js';
import extend_244d6b61 from '/home/ubuntu/frontend/modules/@ergonode/products/src/config/extends.js';
import extend_75b78600 from '/home/ubuntu/frontend/modules/@ergonode/product-templates/src/config/extends.js';
import extend_370c9062 from '/home/ubuntu/frontend/modules/@ergonode/roles/src/config/extends.js';
import extend_1927356d from '/home/ubuntu/frontend/modules/@ergonode/users/src/config/extends.js';
import extend_db652c3c from '/home/ubuntu/frontend/modules/@ergonode/import/src/config/extends.js';
import extend_5ebe074f from '/home/ubuntu/frontend/modules/@ergonode/batch-actions/src/config/extends.js';
import extend_bf75183c from '/home/ubuntu/frontend/modules/@ergonode/products-comments/src/config/extends.js';
import extend_84ac051e from '/home/ubuntu/frontend/modules/@ergonode/product-batch-actions/src/config/extends.js';
import extend_51132908 from '/home/ubuntu/frontend/modules/@ergonode/workflow/src/config/extends.js';

export default {
	extend_3f914814,
	extend_cf4eed3c,
	extend_050e59ce,
	extend_18a9d2fa,
	extend_27167d48,
	extend_6b0b720e,
	extend_a8ee3666,
	extend_c5fdeb6e,
	extend_2969a04d,
	extend_2fd8a38c,
	extend_244d6b61,
	extend_75b78600,
	extend_370c9062,
	extend_1927356d,
	extend_db652c3c,
	extend_5ebe074f,
	extend_bf75183c,
	extend_84ac051e,
	extend_51132908,
};
